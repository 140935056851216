<template>
 <b-row>
  <b-col md="8">
    <!-- <AddSocialPost @addPost="addPost"></AddSocialPost> -->
              <div  v-for="post in socialNews" :key="post.id">
                <social-news :post="post"></social-news>
              </div>
  </b-col>  
          <b-col md="4">
           <recent-birthdate/>
          <!-- <iq-card :style="webanner" class="web-banner">
            <template v-slot:headerTitle>
            </template>
          </iq-card> -->
           <events/>        
        </b-col> 
</b-row>
</template>
<script>
import { socialvue } from '../../../config/pluginInit'
import AddSocialPost from '../../Apps/Social/Components/AddSocialPost'
import SocialNews from '../../Apps/Social/Components/SocialNews'
import { Posts } from '../../../FackApi/api/SocialPost'
import axios from '../../../components/axios'
import RecentBirthdate from '../../SocailMain/Bithdate/RecentBirthdate'
import Events from '../../Apps/Social/Components/Events'
export default {
  components: {AddSocialPost, SocialNews, Posts,RecentBirthdate,Events},  
  name: 'NewsEvent',
  mounted () {
    socialvue.index()
    var parent = this

    var config = {
      method: "get",
      url: 'news',
    }

    axios(config).then(res => {

        parent.socialNews = res.data.allNews;

    }).catch(err => {
        console.log(err.response);
    })
  },
  data () {
    return {
      socialPosts: Posts, 
      socialNews:[],
      daybanner: {
          backgroundImage: `url(${require('@/assets/images/dashboard-graphic2.png')})`
        },
       webanner: {
          backgroundImage: `url(${require('@/assets/images/dashboard-graphic1.png')})`
        },
       suggestionEvent: [
        {
          img: require('../../../assets/images/page-img/42.png'),
          title: 'Iqonic Studio',
          des: 'Lorem Ipsum',
          otherImg: require('../../../assets/images/small/img-1.jpg')
        },
        {
          img: require('../../../assets/images/page-img/43.png'),
          title: 'Cakes & Bakes ',
          des: 'Lorem Ipsum',
          otherImg: require('../../../assets/images/small/img-2.jpg')
        }
      ],   
       event: [
        {
          img: require('../../../assets/images/page-img/s4.jpg'),
          heading: 'Web Workshop',
          time: '1 hour ago'
        },
        {
          img: require('../../../assets/images/page-img/s5.jpg'),
          heading: 'Fun Events and Festivals',
          time: '4 hour ago'
        }
      ],
      bdaybanner: [
        {
          img: require('../../../assets/images/dashboard-graphic2.png'),
          heading: 'birthday banner'
        },
        {
          img: require('../../../assets/images/dashboard-graphic1.png'),
          heading: 'wfd'
        }
      ],
      story: [
        { title: 'Web Design', time: '1 hour ago', image: require('../../../assets/images/page-img/s1.jpg'), class: 'mb-4', isActive: 'active' },
        { title: 'App Design', time: '4 hour ago', image: require('../../../assets/images/page-img/s2.jpg'), class: 'mb-4', isActive: '' },
        { title: 'Abstract Design', time: '9 hour ago', image: require('../../../assets/images/page-img/s3.jpg'), class: '', isActive: '' }
      ],
        timelineItems: [
        {
          color: 'primary',
          title: 'Client Login',
          right: '24 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
            ]
          }
        },
        {
          color: 'success',
          title: 'Scheduled Maintenance',
          right: '23 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
            ]
          }
        },
        {
          color: 'danger',
          title: 'Dev Meetup',
          right: '20 November 2019',
          description: 'Bonbon macaroon jelly <b-link href="">beans gummi</b-link> bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
              require('../../../assets/images/user/user-05.jpg'),
              require('../../../assets/images/user/user-06.jpg'),
              require('../../../assets/images/user/user-07.jpg'),
              require('../../../assets/images/user/user-08.jpg'),
              require('../../../assets/images/user/user-09.jpg'),
              require('../../../assets/images/user/user10.jpg')
            ]
          }
        },
        {
          color: 'primary',
          title: 'Client Call',
          right: '19 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
            ]
          }
        },
        {
          color: 'warning',
          title: 'Mega Event',
          right: '15 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
            ]
          }
        }
      ],
      friends: [
        { name: 'Paul Molive', role: 'Web Designer', image: require('../../../assets/images/user/user-01.jpg') },
        { name: 'Paul Molive', role: 'trainee', image: require('../../../assets/images/user/user-01.jpg') }
      ],
      userBio: [
        { title: 'Joined', description: 'November 15, 2012' },
        { title: 'Lives', description: 'United States of America' },
        { title: 'Email', description: '<a href="mailto:nikjone@gmail.com"> nikjone@gmail.com</a>' },
        { title: 'Url', description: '<a href="https://getbootstrap.com/docs/4.0/getting-started/introduction/" target="_blank"> www.bootstrap.com </a>' },
        { title: 'Contact', description: '<a href="tel:001 4544 565 456">(001) 4544 565 456</a>' }
      ],
      story: [
        { title: 'Web Design', time: '1 hour ago', image: require('../../../assets/images/page-img/s1.jpg'), class: 'mb-4', isActive: 'active' },
        { title: 'App Design', time: '4 hour ago', image: require('../../../assets/images/page-img/s2.jpg'), class: 'mb-4', isActive: '' },
        { title: 'Abstract Design', time: '9 hour ago', image: require('../../../assets/images/page-img/s3.jpg'), class: '', isActive: '' }
      ],
      suggestions: [
        { name: 'Paul Molive', mutual_friend: 'Today', image: require('../../../assets/images/user/user-01.jpg') },
        { name: 'Paul Molive', mutual_friend: 'Tomorrow', image: require('../../../assets/images/user/user-01.jpg') }
      ],
      news: [
        { description: 'there is a meetup in your city on friday at 19:00.<a href="#">see details</a>' },
        { description: '20% off coupon on selected items at pharmaprix' }
      ],
      twitterFeed: [
        {
          image: require('../../../assets/images/page-img/42.jpg'),
          name: 'UI/Ux Designer',
          description: 'Creativity Design'
        },
        {
          image: require('../../../assets/images/page-img/43.jpg'),
          name: 'Marketing',
          description: 'Accounting'
        },
        {
          image: require('../../../assets/images/page-img/44.jpg'),
          name: 'Web Developer',
          description: 'uniq Concept'
        },
        {
          image: require('../../../assets/images/page-img/45.jpg'),
          name: 'Latest News',
          description: 'Line information'
        }
      ],
      recentPages: [
        {
          image: require('../../../assets/images/page-img/46.jpg'),
          name: 'Food town'
        },
        {
          image: require('../../../assets/images/page-img/47.jpg'),
          name: 'Touro Univercity'
        },
        {
          image: require('../../../assets/images/page-img/48.jpg'),
          name: 'Moviehouse & Eatery'
        },
        {
          image: require('../../../assets/images/page-img/49.jpg'),
          name: 'Coffee + Crisp'
        }
      ]
      
   
    }
  },
 methods: {
   async addPost (post) {
     

      var parent  = this
      const config = {
          method: "post",
          url: "createpost",
          data:post,
          headers: { 'content-type': 'multipart/form-data' }
      };
  
        await axios(config).then(res => {
        
          parent.socialPosts.unshift(res.data.post)
          this.page += 1;
          // this.$root.$emit('user', res.data.user);
          // this.$router.push('/')
        }).catch(err => {
            console.log(err.response);
            // if(typeof err.response.data !== 'undefined' && err.response.data ){
            //   alert('Caption is required');
            // }
        })
    }
  }
}
</script>
